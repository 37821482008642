import '../styles/index.scss';
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import Swiper, { Mousewheel, Navigation, Pagination } from 'swiper';
import gsap from 'gsap';

if (process.env.NODE_ENV === 'development') {
    require('../index.html');
}
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function nav() {
    const nav = document.querySelector('.nav');
    const navLinks = nav.querySelectorAll('.nav__link');
    const navMain = nav.querySelector('.nav__main');
    const navToggle = nav.querySelector('.nav__toggle');
    const scrollThreshold = 250;
    let lastPosition = 0;

    for (let link of navLinks) {
        link.addEventListener('click', function (e) {
            for (let l of navLinks) {
                l.classList.remove('nav__link--active');
            }
            e.target.classList.add('nav__link--active');
            navMain.classList.remove('nav__main--open');
            gsap.to(window, {
                duration: (a, b, c) => {
                    const elTop = document.querySelector(`#${e.target.href.split('#')[1]}`).getBoundingClientRect().y;
                    return Math.abs(elTop) / 4000;
                }, scrollTo: {y: `#${e.target.href.split('#')[1]}`, offsetY: 75}
            });
            e.preventDefault();
        });
    }

    navToggle.addEventListener('click', function (e) {
        navMain.classList.toggle('nav__main--open');
        e.preventDefault();
    });

    const sections = document.querySelectorAll('section, footer');

    function scrollSpy() {
        sections.forEach((section, index) => {
            const {y, height} = section.getBoundingClientRect();
            const {innerHeight} = window;
            const inView = 0 < (y + height) && (y + height) < (height + innerHeight);
            if (inView && y < (innerHeight / 2)) {
                navLinks.forEach(link => {
                    link.classList.remove('nav__link--active');
                    if (link.href.includes(section.id) && section.id) {
                        link.classList.add('nav__link--active');
                    }
                });
            }
        });
    };
    window.addEventListener('scroll', scrollSpy);

    window.addEventListener('scroll', function (e) {
        if (window.scrollY > scrollThreshold) {
            nav.classList.add('nav--scrolled');
        } else {
            nav.classList.remove('nav--scrolled');
        }
        const threshold = Math.abs(window.scrollY - lastPosition) > 150;
        if (window.scrollY > lastPosition && threshold) {
            nav.classList.add('nav--scrolled-down');
            nav.classList.remove('nav--scrolled-up');
            lastPosition = window.scrollY;
        } else if (window.scrollY < lastPosition && threshold) {
            nav.classList.add('nav--scrolled-up');
            nav.classList.remove('nav--scrolled-down');
            lastPosition = window.scrollY;
        }
    });
}

function advantagesAnimation() {
    // if (window.innerWidth > 768) {
    //     const sections = document.querySelectorAll('.section-advantages__element:not(.section-advantages__element--1)');
    //
    //     const tl = gsap.timeline({
    //         paused: true,
    //         scrollTrigger: {
    //             trigger: '.section-advantages',
    //             start: `top top+=80px`,
    //             end: `+=${sections.length * 100}%`,
    //             scrub: 0.0001,
    //             snap: {
    //                 snapTo: 1 / sections.length,
    //                 delay: 0.01,
    //                 duration: 0.3,
    //                 ease: 'linear',
    //             },
    //             pin: true,
    //         }
    //     })
    //         .add(
    //             gsap.timeline().to('.section-advantages__element--1', {opacity: 0, top: '30%'})
    //         );
    //
    //     sections.forEach((section, index) => {
    //         if (index === (sections.length - 1)) {
    //             tl.add(
    //                 gsap.timeline().to(section, {opacity: 1, duration: 0.25, delay: -0.1})
    //             );
    //             return;
    //         }
    //         tl.add(
    //             gsap.timeline()
    //                 .to(section, {opacity: 1, duration: 0.25, delay: -0.1})
    //                 .to(section, {opacity: 0, top: '30%'})
    //         );
    //     });
    // }
    Swiper.use(Mousewheel);
    new Swiper('.section-advantages .swiper-container', {
        direction: 'vertical',
        slidesPerView: 1,
        mousewheel: {
            releaseOnEdges: true,
        },
    });

}

function stickyMenu(selector, sectionSelector) {
    const menuItems = document.querySelectorAll(`${selector} .sticky-menu-item`);
    document.querySelectorAll(`${selector} .sticky-menu-item a`).forEach(link => {
        link.addEventListener('click', function (e) {
            const selector = e.target.getAttribute('href');
            if (selector) {
                const fromTop = document.querySelector(selector).getBoundingClientRect().y;
                const scrollTo = window.scrollY + fromTop - 80;
                window.scrollTo(0, scrollTo);
            }
            e.preventDefault();
        });
    });
    const portfolios = document.querySelectorAll(`${sectionSelector}`);

    function scrollSpy() {
        portfolios.forEach((portfolio, index) => {
            const elTop = portfolio.getBoundingClientRect().y;
            if (elTop < (window.innerHeight / 2 + 100) && elTop > 0) {
                document.querySelector(`${selector} .sticky-menu-item--active`).classList.remove('sticky-menu-item--active');
                menuItems[index].classList.add('sticky-menu-item--active');
            }
        });
    };
    window.addEventListener('scroll', scrollSpy);
}

function testimonials() {
    Swiper.use([Navigation, Pagination]);
    let swiper = new Swiper('.section-testimonials__container .swiper-container', {
        autoHeight: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
        },
    });
}

document.addEventListener('DOMContentLoaded', function () {
    nav();
    stickyMenu('.advantages-menu', '.section-advantages__element');
    stickyMenu('.portfolio-menu', '.portfolio-item');
    setTimeout(function () {
        testimonials();
        advantagesAnimation();
    }, 500);
});
